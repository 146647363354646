import { z } from 'zod'

export const StringListSchema = z.union([z.string(), z.array(z.string())])
export const StringSetSchema = StringListSchema.transform(
  arg => new Set(Array.isArray(arg) ? arg : arg.split(',')),
)

export const NumberSetSchema = z
  .array(z.number())
  .transform(arg => [...new Set(arg)])

export const JSONStringSchema = z.string().transform((arg, ctx) => {
  try {
    return JSON.parse(arg) as object
  } catch (error) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'invalid json',
    })
    return z.never
  }
})
